const hostname = window && window.location && window.location.hostname;
let backendHost;
console.log(hostname);
if(hostname === 'localhost' || hostname === 'university') {
	backendHost = '//university';  
}else{ 
	backendHost = 'https://'+hostname;
}

export const UNIVERSITY_API_URL = backendHost;

export function getHeader(){
	var token = localStorage.token?localStorage.token:'';
	return {
		"X-Requested-With": "XMLHttpRequest",
		"Content-Type": "application/json;charset=UTF-8",
		"Authorization":"Bearer "+token
	};
}

export const DATE_FORMAT = 'D MMMM YYYY';

export function getUnivID(){
	var univId = getParameterByName('univid');
	if(univId){
		return univId;
	}
	if(localStorage.university){
		var univ = JSON.parse(localStorage.university);
		univId = univ.ID;	
	}
	if(univId){
		return univId;
	}
	if(hostname === 'localhost' || hostname === 'university') {
		return 1017;
	}else{
		return 1001;
	}
}
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
